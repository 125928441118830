.lr-loader {
  display: block;
  margin: 2em 0;
  padding: 24px;

  &.inline {
    display: inline-block;
    padding: 0px 12px 0px 0px;
    margin: 0px;
  }
}
