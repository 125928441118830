/*
This is the main styles file of our applications.
This is the point of entry and only stylesheet we should be importing in the app.
Anything else needed should be in one of the directories imported below.
*/

@charset 'utf-8';

//Abstracts (Files inside this folder can contain abstract settings, helpers or functions. They have no direct output.)
@import './abstracts/_abstracts-dir';

//Base (Files inside this folder can contain global styles used in the project, typography, layout etc.)
@import './base/__base-dir';

//Components (Files inside this folder should contain all styles relating to a reusable component.)
@import './components/_components-dir';

//Vendor (Files inside this folder should contain all 3rd party styles)
@import './vendor/__vendor-dir';

.date-input {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  background: transparent;
  border: 1px solid var(--textfield-border--neutral);
  border-radius: var(--border-radius--xxs);
  height: var(--space--xl);
  width: 100%;
  padding: var(--space-inset-squish--s);
}
