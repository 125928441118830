.pill-sm {
  font-size: 13px;
  display: inline-block;
  padding: 4px 12px;
  border-radius: 16px;
  font-weight: bold;
  box-shadow: $lr-box-shadow-light;
}

.pill-white {
  background: rgba(255, 255, 255, 0.75);
  color: $dark-grey;
}

.pill-blue {
  background: $blue;
  color: $white;

  &:hover {
    color: $white !important;
  }
}
