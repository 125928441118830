.action-bar {
  .action-bar-item {
    display: inline-block;
  }

  .action-dropdown {
    border-radius: 8px;
    position: absolute;
    background: #fff;
    width: 240px;
    max-width: 94vw;
    box-shadow: $lr-box-shadow;
    overflow: hidden;
    padding: 0px;
    z-index: 999;
    margin-top: 4px;

    @include down('sm') {
      width: 185px;
    }

    @include up('md') {
      width: 220px;
    }
  }
}

.dark-mode .action-bar .action-dropdown {
  background: $dark-mode-dark;
}
