.dark-mode .modal-wrapper {
  .modal-container {
    background: $dark-mode-dark;
  }

  .modal-header {
    border-bottom: 1px solid #333;
  }
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.65);
  height: 100vh;
  z-index: 20000;
  padding: 8px;

  .modal-container {
    background: $white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    border-radius: $border-radius-lg;
    position: relative;

    &.modal-windowless {
      background: transparent;
      box-shadow: none;
      position: static;

      .modal-header {
        border: none;

        span.close {
          position: absolute;
          right: 24px;
          top: 24px;
          font-size: 24px;
          cursor: pointer;
          color: #fff;
        }
      }
    }
  }

  .modal-header {
    border-bottom: 1px solid $light-grey;
    min-height: 52px;

    h2 {
      max-width: 90%;
    }

    span.close {
      position: absolute;
      right: 16px;
      top: 18px;
      font-size: 20px;
      cursor: pointer;
      color: $grey;
    }
  }

  .modal-content {
    overflow: auto;

    &.overflow-visible {
      overflow: visible;
    }
  }

  .modal-footer {
    border-top: 1px solid $light-grey;
    text-align: right;
  }
}
