/*
All font styling that isn't unique to a certain component should be done in here.
Mobile first then use the media query mixins for other sizes.
*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button,
h1,
a.btn {
  font-family: 'Assistant', sans-serif;
}

.dark-mode h2 {
  color: $dark-mode-text;
}

h2 {
  color: $darker-grey;
}

p,
a,
textarea,
input,
form,
label {
  font-family: 'Assistant', sans-serif;
}

body,
input,
textarea,
select {
  margin: 0;
  padding: 0;
  font-family: 'Assistant', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.secondary-font {
  font-family: 'Assistant', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark-mode a:not(.btn) {
  color: $blue;
  font-weight: 700;
  text-decoration: none;

  &:hover {
    color: rgba(84, 105, 197, 0.8);
  }
}

a {
  cursor: pointer;

  &.white {
    color: $white;
  }

  &.back-link {
    color: $dark-grey;
    font-weight: 400;
    font-size: 14px;
  }
}

label {
  font-weight: 700;
  font-size: 12px;
  display: block;
  margin-bottom: 4px;
}

p {
  line-height: 1.8em;
  font-size: 14px;
  padding: 0;
  margin-top: 0;
  margin-bottom: 16px;
}

button {
  font-size: $font-size;
}

.dark-mode .lr-small-text {
  color: $dark-mode-text-light;
}

.lr-small-text {
  font-size: 13px !important;
  color: $darker-grey;
}

.dark-mode .lr-md-text {
  color: $dark-mode-text-light;
}

.lr-md-text {
  font-size: 14px !important;
  color: $darker-grey;
}

.dark-mode .lr-large-text {
  color: $dark-mode-text-light;
}

.lr-large-text {
  font-size: 18px !important;
  line-height: 1.8em;
}

h1 {
  font-size: 26px;
  font-weight: 800;
  padding: 0;
  margin-top: 0;
  margin-bottom: 16px;

  &.massive {
    font-size: 80px;
  }

  @include up('sm') {
    font-size: 30px;
  }
}

h2 {
  font-size: 22px;
  font-weight: 800;
  margin-top: 0;
  margin-bottom: 12px;
}

h3 {
  font-size: 16px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 4px;

  @include up('sm') {
    font-size: 16px;
    margin-bottom: 12px;
  }
}

h4 {
  font-size: 12px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 4px;
  text-transform: uppercase;

  @include up(sm) {
    font-size: 12px;
    margin-bottom: 6px;
  }
}

.lr-big-number {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.lr-jumbo-txt {
  font-weight: 800;
  font-size: 34px;
  margin: 0;

  @include up(md) {
    font-size: 38px;
  }

  @include up(lg) {
    font-size: 44px;
  }
}

.font-weight-light {
  font-weight: 300;
}

.bold {
  font-weight: 700;
}

.semibold {
  font-weight: 700;
}

.caps {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.strikethrough {
  text-decoration: line-through;
}

.light-grey-text {
  color: $light-grey !important;
}

.dark-mode .grey-text {
  color: $dark-mode-text !important;
}

.grey-text {
  color: $grey !important;
}

.dark-mode .dark-grey-text {
  color: $dark-mode-text !important;
}

.dark-grey-text {
  color: $dark-grey !important;
}

.dark-mode .darker-grey-text {
  color: $dark-mode-text !important;
}

.darker-grey-text {
  color: $darker-grey !important;
}

.black-text {
  color: #000 !important;
}

.dark-mode .black-text {
  color: $dark-mode-text !important;
}

.red-text {
  color: $red !important;
}

.dark-mode .red-text {
  color: $dark-mode-red !important;
}

.blue-text {
  color: $blue !important;
}

.light-blue-text {
  color: $light-blue !important;
}

.green-text {
  color: $green !important;
}

.dark-mode .green-text {
  color: $dark-green !important;
}

.white-text {
  color: $white !important;
}

.faded-text {
  opacity: 0.7;
}

.dark-mode .lr-blockquote {
  border: 1px solid #333;
}

.lr-blockquote {
  border: 1px solid $light-grey;
  border-radius: $border-radius;
  line-height: 1.4em;
}

.fa,
.fa-2x,
.fa-3x,
.fa-4x {
  color: $blue;
}

.fa-2x {
  font-size: 24px;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.font-size-xs {
  font-size: $font-size-xs;
}

.font-size-sm {
  font-size: $font-size-sm;
}

.font-size {
  font-size: $font-size;
}

.font-size-lg {
  font-size: $font-size-lg;
}

.font-size-xl {
  font-size: $font-size-xl;
}

h1.dash-page-heading {
  font-size: 24px;
  margin-bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.emphasis {
  color: $life-theme-primary-color;
}

// New styles for org dashboard

.org-dash {
  font-family: 'Assistant', sans-serif;

  button,
  h1,
  h2,
  a.btn {
    font-family: 'Assistant', sans-serif;
  }

  p,
  a,
  label,
  form,
  input,
  textarea,
  select {
    margin: 0;
    padding: 0;
    font-family: 'Assistant', sans-serif;
    font-weight: 400;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-transform: none;
  }

  h1 {
    font-size: 28px;
    font-weight: 800;
    padding: 0;
    margin-top: 0;
    margin-bottom: 12px;

    // @include up('sm') {
    //   font-size: 30px;
    // }
  }

  h2 {
    font-size: 24px;
    font-weight: 800;
    margin-top: 0;
    margin-bottom: 10px;
  }

  h3 {
    @include up('sm') {
      margin-bottom: 16px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 400;
  }

  a {
    color: var(--colors-action);
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
  }

  .fa,
  .fa-2x,
  .fa-3x,
  .fa-4x {
    color: black;
  }
}
