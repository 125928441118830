#map {
  z-index: 1;
}

.leaflet-container {
  font-family: 'Alegreya Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  font-weight: 500 !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;

  font-size: 14px !important;

  .leaflet-popup-content {
    line-height: 1.1;
  }

  a.leaflet-popup-close-button {
    top: 8px !important;
    right: 8px !important;
  }

  .btn {
    color: white !important;
    font-size: 12px;
    font-weight: 600;
  }
}

.map-center {
  top: 10px;
  right: 10px;
  position: absolute;
  z-index: 400;
  border-radius: 4px;
}
