.dark-mode .ReactTable {
  .-pagination .-btn {
    color: $dark-mode-text;
    background: $dark-mode-darker;
  }

  .-pagination input,
  .-pagination select {
    color: $dark-mode-text;
    background: $dark-mode-darker;
  }

  .rt-noData {
    background: $dark-mode-dark;
    color: $dark-mode-text;
  }
}

// TODO: better organize this component's style
.data-table {
  .top-bar {
    justify-content: flex-end;
  }
}

.map-mode-toggle {
  z-index: 1;
  cursor: pointer;
}

.download-button {
  z-index: 1;
  cursor: pointer;
}

.filter-container {
  position: relative;
}

.filter-container-item {
  position: relative;
}

.text-filter-container .lr-loader {
  margin-left: -34px !important;
  margin-top: 14px !important;
  vertical-align: top;
}

.text-filter {
  max-width: 500px;
  padding: 3px 6px;
  display: inline;
  border: 1px solid #e0e0e0;
  font-size: 14px;
  background-color: #ffffff;
}

.dark-mode .select-filter {
  background: $dark-mode-dark;
}

.select-filter {
  cursor: pointer;
  position: relative;
  padding: 12px 36px 12px 16px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  display: inline-block;
  background: #fff;
  user-select: none;
}

.available-filters.card {
  position: absolute;
  font-size: 14px;
  z-index: 2;
  min-height: 50%;
  padding: 6px 0 !important;
  top: 46px;
  left: 0;
  box-shadow: $lr-box-shadow-strong;
  min-width: 150px;
}

.dark-mode .filter-field {
  &::after {
    border-left: 4px solid $dark-mode-text;
  }

  &:hover {
    background: $dark-mode-light;
  }
}

.filter-field {
  cursor: pointer;
  padding: 8px 16px;
  min-width: 100px;

  &:hover {
    background: $lightest-grey;
  }

  &::after {
    content: '';
    position: absolute;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid #000;
    top: 13px;
    right: 16px;
  }
}

.dark-mode .filter-value {
  &:hover {
    background: $dark-mode-light;
  }

  .back-arrow {
    border-right: 4px solid $dark-mode-text;
  }
}

.filter-value {
  cursor: pointer;
  padding: 8px 16px;
  min-width: 100px;

  &:hover {
    background: $lightest-grey;
  }

  .back-arrow {
    content: '';
    position: absolute;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 4px solid #000;
    top: 13px;
    left: 8px;
  }
}

.applied-filter-container [bp~='full-width-until@md'] {
  margin-left: -5px;
}

.applied-filter {
  display: inline-flex;
  background: $blue;
  color: #fff;
  font-weight: 700;
  border-radius: $border-radius-lg;
  padding: 1px;

  .close-icon {
    cursor: pointer;
    position: relative;
    top: 5px;

    &:before {
      content: '\2715';
      font-size: 16px;
      font-weight: 400;
    }
  }

  .applied-filter-label {
    padding: 6px;
  }

  .applied-value {
    background: $white;
    color: $blue;
    border-radius: 7px;
    padding: 6px;
    margin-left: 2px;
  }
}

.dark-mode .filters-clear {
  color: $dark-mode-text-light;
}

.filters-clear {
  display: inline-flex;
  padding: 6px;
  color: $darker-grey;
  font-weight: 600;
  cursor: pointer;
}

.ReactTable.table {
  padding: 0 !important;
  border: none;
  box-shadow: none;

  @include up('sm') {
    padding: 16px;
  }

  .rt-thead.-header {
    box-shadow: none;
    padding-bottom: 0.5em;

    .rt-tr {
      text-align: start;
      font-weight: 600;

      .rt-th {
        border-right: none;
      }

      .-sort-asc {
        box-shadow: none;

        .rt-resizable-header-content:after {
          content: '↓';
          padding-left: 5px;
          color: $green;
        }
      }
      .-sort-desc {
        box-shadow: none;

        .rt-resizable-header-content:after {
          content: '↑';
          padding-left: 5px;
          color: $green;
        }
      }
    }
  }

  .rt-tbody {
    padding-bottom: 1em;

    .rt-tr-group {
      border: none;
      cursor: pointer;
      margin-bottom: 2px;

      .rt-td {
        border: none;
      }
    }
  }

  .-pagination {
    box-shadow: none;
    border: none;
    padding-top: 1em;

    .-btn {
      border-radius: $border-radius;
    }

    .-pageSizeOptions select {
      border-radius: 3px;
    }
  }

  .rt-noData {
    background: transparent;
  }

  .archive-button {
    margin: auto;
    color: #5469c5;
    cursor: pointer;
  }

  .archive-button:hover {
    color: red;
  }
}
