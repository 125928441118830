.checkbox-container {
  position: relative;

  input[type='radio'] {
    opacity: 0;
    width: 100%;
    height: 20px;
    position: absolute;
    top: -1px;
    z-index: 1000;
    cursor: pointer;
    margin: 0;

    & + label {
      position: relative;
      text-transform: none;
      padding-left: 30px;
      display: inline-block;
      margin-bottom: 12px;
      font-size: $font-size;
      font-weight: normal;
    }

    & + label:before,
    & + label:after {
      position: absolute;
    }

    & + label:before {
      content: '';
      top: -1px;
      left: 0;
      display: inline-block;
      height: 20px;
      width: 20px;
      margin-top: 1px;
      border: 1px solid #222;
      border-radius: 3px;
    }

    &:checked + label:after {
      content: '';
    }

    & + label:after {
      content: none;
      top: 5px;
      left: 5px;
      display: inline-block;
      height: 6px;
      width: 10px;
      border-left: 2px solid $dark-green;
      border-bottom: 2px solid $dark-green;
      transform: rotate(-45deg);
    }
  }
}

.dark-mode .checkbox-container input[type='radio'] {
  & + label {
    color: $dark-mode-text;
    font-weight: normal;
  }

  & + label:before {
    border-color: $dark-mode-text;
  }

  & + label:after {
    color: $dark-green;
  }
}
