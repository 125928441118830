.slider-container {
  position: relative;

  .min,
  .max {
    position: absolute;
    top: 7px;
    opacity: 0.7;
    font-size: 18px;
    color: #fff;
  }

  .min {
    left: 13px;
    transform: translateX(-50%);
  }

  .max {
    right: 13px;
    transform: translateX(50%);
  }

  .slider.side-labels {
    width: calc(100% - 70px);
    margin: 16px auto 0 auto;
  }
}
