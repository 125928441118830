.org-dash {
  .card {
    background: $org-dash-primary;
    box-shadow: none;
    border-radius: $org-dash-border-radius;
    padding: $org-dash-padding-md;

    .card-big-number {
      font-size: 28px;
      font-weight: 800;
    }

    .card-small-number {
      font-size: 18px;
      font-weight: 700;
      color: $org-dash-accent;
    }
  }
}

/*
  Styling for basic Leadrilla card. Most content in a dashboard page should go inside a card.

  Font inside of cards should be styled as follows:
  - Title: <h2>
  - Main text: <p>
  - Sub-title: <h3>
  - Small text and disclaimers: .lr-small-text
*/

.dark-mode .card {
  background-color: #1e1e1e;

  .card-header {
    border-bottom: 0.5px solid #333;

    &.no-border-dark {
      border-bottom: none;
    }
  }
}

.card {
  position: relative;
  padding: $lr-padding;
  background-color: $white;
  box-shadow: $lr-box-shadow-light;
  min-height: 150px;
  transition: 0.1s;
  border-radius: $border-radius;
  overflow: hidden;

  &.overflow-visible {
    overflow: visible;
  }

  &.disabled {
    color: $grey;
  }

  &.metric {
    text-align: center;

    h3 {
      margin-top: $lr-padding;
      margin-bottom: 0;
      font-size: 16px;
    }

    p {
      font-weight: 700;
      font-size: 48px;
    }
  }

  // p a {
  //   font-weight: 400;
  //   text-decoration: underline;
  // }

  // a {
  //   font-weight: 500;
  //   font-size: 14px;
  // }

  &.blue {
    background: $light-blue;
    color: #fff;
  }

  .card-header {
    border-bottom: 0.5px solid #ededed;
    margin: 0px -16px;
    padding: 8px $lr-padding;
    margin-top: -16px;
    margin-bottom: 16px;

    h3 {
      margin-bottom: 0;
      display: inline-block;
    }
  }

  .card-header-blue {
    background: $blue;
    color: $white;
    border: none;
  }
}
