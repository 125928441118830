/*
  Styles for Leadrilla Action Bar
*/

.lr-notification-bar {
  transition: top 0.3s ease 0s;
  position: fixed;
  width: 100%;
  height: 4.5em;
  top: 0;
  right: 0;
  padding: $lr-padding-lg;
  color: $white;
  font-weight: 700;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
  opacity: 0.9;
  z-index: 200;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @include down('sm') {
    width: 100%;
  }

  &.hide {
    top: -8em;
  }

  &.error {
    background: $red;
  }

  &.success {
    background: $green;
  }

  &.cancel {
    background: $grey;
  }

  a {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  p {
    margin-bottom: 0;
  }

  .lr-notification-close {
    position: absolute;
    right: 0;
    margin-right: 16px;
    cursor: pointer;
    font-weight: 600;
  }
}

.dark-mode .lr-notification-bar.error {
  color: $dark-mode-red;
}
