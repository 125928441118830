.slider {
  user-select: none;
  margin-top: $lr-margin;
  height: 40px;

  .track {
    top: 18px;
    height: 5px;
    background: $light-grey;
  }

  .thumb {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: var(--colors-action);
    color: $white;
    text-align: center;
    font-weight: bold;
    font-size: $font-size-lg;
    padding: 6px 0;
    cursor: pointer;
  }
}

.dark-mode .slider {
  .track {
    background: $dark-mode-light;
  }
}
