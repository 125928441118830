.dark-mode .card .react-tabs__tab--selected {
  background: $dark-mode-dark;
  color: $dark-mode-text;
}

.card {
  .react-tabs__tab {
    border: none;
    outline: none;
  }

  .react-tabs__tab:focus {
    box-shadow: none;
  }

  .react-tabs__tab--selected {
    font-weight: bold;
  }
}
