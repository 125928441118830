.dark-mode .date-filter {
  .filter-btn {
    svg {
      color: $dark-mode-text;
    }
  }

  .active-filter-btn {
    background: $dark-mode-darker !important;

    .btn-head {
      background-color: $dark-mode-darker;
      color: $dark-mode-text;
    }
  }
  .filter-dropdown {
    background-color: $dark-mode-dark;

    .dropdown-body {
      .react-datepicker {
        border-color: $dark-mode-light;
        background-color: $dark-mode-light;

        .react-datepicker__header {
          background-color: $dark-mode-light;
          border-color: $dark-mode-dark;

          .react-datepicker__current-month,
          .react-datepicker__day-name {
            color: white;
          }
        }

        .react-datepicker__day {
          color: white;

          &:hover {
            background: $dark-mode-dark;
          }
        }

        .react-datepicker__day.react-datepicker__day--in-selecting-range {
          &:not(.react-datepicker__day--selected) {
            background: #9aadc0;
            color: white;
          }

          &:hover {
            background: $primary;
            color: $white;
          }
        }

        .react-datepicker__day.react-datepicker__day--keyboard-selected {
          &:not(.react-datepicker__day--in-range):not(.react-datepicker__day--in-selecting-range) {
            color: white;
          }
        }

        .react-datepicker__day.react-datepicker__day--selected,
        .react-datepicker__day.react-datepicker__day--in-range,
        .react-datepicker__day.react-datepicker__day--in-selecting-range {
          &:hover {
            background: $primary;
          }
        }
      }
    }

    .dropdown-foot {
      border-color: $dark-mode-light;
    }
  }
}

.date-filter {
  position: relative;
  display: flex;
  width: 100%;
  min-width: 220px;
  height: 40px;

  @include up('sm') {
    width: initial;
  }

  .btn {
    box-shadow: none;
    border: 1px solid var(--colors-subtle);
    border-radius: 4px;
    color: var(--colors-neutral);
    font-size: var(--font-size--base);
    font-weight: var(--font-weight--regular) !important;
  }

  .filter-btn {
    width: 100%;
    padding: 0 12px;
    text-align: left;

    .fa-caret-down {
      float: right;
      margin-top: 2px;
    }

    div {
      align-items: center;
      justify-content: center;
    }
  }

  .active-filter-btn {
    width: 100%;
    padding: 0 16px;
    text-align: left;
    position: relative;
    box-shadow: none;
    background: var(--colors-foreground);

    .btn-head {
      position: absolute;
      background: var(--colors-foreground);
      color: $grey;
      top: -10px;
      left: 8px;
      padding: 0 5px;
      font-size: 12px;
      letter-spacing: 0.5px;
    }

    svg {
      vertical-align: sub;
      margin-top: 3px;
      float: right;

      @include up('sm') {
        margin-left: 10px;
      }
    }
  }

  .filter-dropdown {
    position: absolute;
    width: 100%;
    top: 40px;
    border-radius: 8px;
    border: 1px solid var(--colors-hairline);
    background-color: var(--colors-foreground);
    z-index: 11;
    overflow: visible;
    right: 0;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);

    @include up('sm') {
      min-width: 310px;
    }

    form .react-select {
      margin-top: 0px;
    }

    .dropdown-head {
      .previous-time-input {
        max-width: 74px;
        margin-top: 10px;
      }
    }

    .dropdown-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      .cal-label {
        color: $org-dash-subtle-font;

        .selected-date {
          color: $org-dash-accent;
          font-weight: 600;
        }
      }

      .react-datepicker {
        border-color: #ddd;
        margin-bottom: var(--space--l);

        .react-datepicker__header {
          background-color: white;
          border-color: #ddd;
        }

        .react-datepicker__day.react-datepicker__day--keyboard-selected {
          background: none;

          &:not(.react-datepicker__day--in-range):not(.react-datepicker__day--in-selecting-range) {
            color: black;
          }
        }

        .react-datepicker__day.react-datepicker__day--selected {
          background: $primary;
        }

        .react-datepicker__day.react-datepicker__day--in-selecting-range {
          &:not(.react-datepicker__day--selected) {
            background: #d3e8ff;
            color: black;
          }

          &:hover {
            background: $primary;
            color: $white;
          }
        }

        .react-datepicker__day.react-datepicker__day--in-range {
          background: $primary;
        }

        .react-datepicker__day.react-datepicker__day--range-start {
          background: $primary;
        }

        .react-datepicker__day.react-datepicker__day--range-end {
          background: $primary;
          color: white;
        }
      }
    }

    .dropdown-foot {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #ddd;

      .checkbox-container {
        display: flex;
        align-items: center;
        cursor: pointer;

        input {
          margin-right: 5px;
          cursor: pointer;
        }

        label {
          margin-bottom: 0;
          cursor: pointer;
          font-weight: normal;
          font-size: 14px;
          text-transform: none;
        }
      }
    }
  }
}

.org-dash {
  .date-filter {
    .btn {
      svg {
        color: #404040;
      }
    }

    .filter-btn,
    .active-filter-btn {
      color: $org-dash-primary-font;
      font-size: 14px;
      font-weight: 400 !important;
      border-radius: 12px;
      border: 2px solid $org-dash-tertiary;

      @include up('sm') {
        font-size: 16px;
        border-radius: 8px;
      }
    }

    .active-filter-btn {
      background: $org-dash-secondary;
      color: $org-dash-primary-font;

      .btn-head {
        background: $org-dash-secondary;
        font-size: 10px;
        font-weight: 400;
      }
    }
  }
}
