.Footer {
  color: $white;
  line-height: 1.8em;
  position: relative;

  &.lr-footer {
    .footer-content {
      padding-top: 80px;
      padding-bottom: 80px;
      z-index: 1;
      position: relative;

      @include down('lg') {
        text-align: center;
      }
    }

    // -webkit-clip-path: polygon(0 7%, 100% 0, 100% 100%, 0 100%);
    // clip-path: polygon(0 7%, 100% 0, 100% 100%, 0 100%);

    // @include up('sm') {
    //   -webkit-clip-path: polygon(0 11%, 100% 0, 100% 100%, 0 100%);
    //   clip-path: polygon(0 11%, 100% 0, 100% 100%, 0 100%);
    // }

    // @include up('md') {
    //   -webkit-clip-path: polygon(0 17%, 100% 0, 100% 100%, 0 100%);
    //   clip-path: polygon(0 17%, 100% 0, 100% 100%, 0 100%);
    // }

    .logo-grey * {
      fill: $grey;
    }

    .footer-logo svg {
      width: 100%;
      max-width: 160px;
      height: 110px;
    }

    .footer-lg-text {
      @include up('sm') {
        font-size: 24px;
      }
    }

    .footer-link-group {
      padding-left: 48px;
      padding-top: 42px;

      @include down('lg') {
        padding: 0;
        margin-top: 16px;

        .footer-link-group-heading {
          color: rgba(255, 255, 255, 0.35);
          text-transform: uppercase;
          font-size: 14px;
          margin-bottom: 8px !important;
        }
      }
    }

    a {
      color: $white;
      font-weight: normal;
      font-size: 16px;

      &.footer-link {
        display: block;
        margin-bottom: 8px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
