.rhap_container {
  border-radius: 8px;
  background: #fafafc !important;
}
.rhap_play-pause-button {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
}
.rhap_progress-indicator {
  width: 10px !important;
  height: 10px !important;
  top: -2px !important;
  background: #26292f !important; // bethpage
}
.rhap_controls-section {
  flex: unset !important;
}
.rhap_progress-bar {
  margin-left: 10px !important;
}
.rhap_current-time {
  font-size: 14px;
}
