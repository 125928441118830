.dark-mode .date-preset {
  border: 1px solid #333;

  span {
    color: $dark-mode-text-light;

    &.active {
      color: $dark-mode-text;
    }

    &:hover {
      color: $dark-mode-text;
    }
  }
}

.date-preset {
  display: inline-block;
  border: 1px solid $light-grey;
  border-radius: 16px;
  padding: 2px 6px;

  span {
    color: $grey;
    margin: 0 2.5px;

    &.active {
      color: black;
    }

    &:hover {
      cursor: pointer;
      color: $darker-grey;
    }
  }
}
