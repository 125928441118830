.dark-mode .rdtPicker {
  background: $dark-mode-light;
  border: 1px solid $dark-mode-light;

  .rdtPrev,
  .rdtSwitch,
  .rdtNext {
    border: none;
  }

  td.rdtYear,
  td.rtdMonth,
  td.rdtDay,
  td.rdtHour,
  td.rdtMinute,
  td.rdtSecond,
  .rdtTimeToggle,
  .rdtSwitch,
  .rdtPrev,
  .rdtNext {
    &:hover {
      background: $dark-mode-dark;
    }
  }

  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background: $blue;
  }

  .rdtPicker td.rdtToday:before {
    border-color: $blue;
  }

  .rdtCounter .rdtBtn:hover {
    background: initial;
  }
}
