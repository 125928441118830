$life-theme-primary-color: #5469c5;
$life-theme-secondary-color: #23378e;
$life-theme-tertiary-color: #20212c;
$life-theme-primary-light-color: #6478cf;
$life-theme-primary-dark-color: #23378e;

$solar-theme-primary-color: #171823;
$solar-theme-secondary-color: #63d785;
$solar-theme-tertiary-color: #252630;
$solar-theme-primary-light-color: #20212c;
$solar-theme-primary-dark-color: #12131d;

//$medicare-theme-primary-color: #75127F;
//$medicare-theme-secondary-color: #ffffff;
//$medicare-theme-tertiary-color: #ffffff;
//$medicare-theme-primary-light-color: #822B8B;
//$medicare-theme-primary-dark-color: #5D0B65;

$medicare-theme-primary-color: #d7246e;
$medicare-theme-secondary-color: #ffffff;
$medicare-theme-tertiary-color: #ffffff;
$medicare-theme-primary-light-color: #df377c;
$medicare-theme-primary-dark-color: #bd145a;

$white: #ffffff;
$black: #000000;
$dark-grey: #333333;
$grey: #777777;
$light-grey: #cccccc;
$lightest-grey: #eeeeee;
