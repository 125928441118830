/*
General Layout for the application
*/

html {
  box-sizing: border-box;
  height: 100%;
}

*,
:after,
:before {
  box-sizing: inherit;
}

body,
#root,
.app {
  height: 100%;
}

[bp~='container'] {
  width: 1392px !important;
  max-width: 100% !important;
}

.hide {
  display: none !important;
}

.lr-page {
  min-height: 100%;
  width: 100%;
}

img {
  max-width: 100%;
}

img.lr-logo-med,
.lr-logo-med > svg {
  width: 64px;
  height: auto;
}

img.lr-logo-sad,
.lr-logo-sad > svg {
  max-width: 100% !important;
  height: auto;
}

.lr-top-8 {
  margin-top: 8vh;
}

.lr-top-16 {
  margin-top: 16vh;
}

/* react-router NavLink active routes */
.lr-active-page-link {
  color: $dark-grey;
}

/* fix to remove highlight when clicking divs */
.lr-no-select {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

#stripes {
  position: absolute;
  width: 100vw;
  height: 80vh;
  overflow: hidden;
  -webkit-transform: skewY(-12deg);
  transform: skewY(-12deg);
  -webkit-transform-origin: 0;
  transform-origin: 0;
  background: linear-gradient(150deg, #eee 15%, #fafafa 94%);
  top: 0;

  span {
    height: 210px;
    position: absolute;
  }

  :first-child {
    width: 33.33333%;
    width: calc(100% / 3);
    left: -16.66666%;
    left: calc(calc(calc(100% / 3) / 2) * -1);
    background: #f3f3f3;
  }

  :nth-child(2) {
    width: 50%;
    width: calc(100% / 2);
    top: 0;
    left: 16.66666%;
    left: calc(calc(100% / 3) / 2);
    right: auto;
    background: #f7f7f7;
  }

  :nth-child(3) {
    width: 66.66667%;
    width: calc(100% / 3 * 2);
    bottom: 0;
    left: 0;
    background: #f0f0f0;
  }

  :nth-child(4) {
    width: 33.33333%;
    width: calc(100% / 3);
    bottom: 0;
    left: 66.66667%;
    left: calc(100% / 3 * 2);
    background: #f6f6f6;
  }
}

.lr-hero {
  position: relative;
  min-height: 100vh;

  .hero-content {
    position: relative;
  }

  h1 {
    font-size: 76px;
  }

  p {
    font-size: 28px;
  }

  @include down('lg') {
    h1 {
      font-size: 64px;
    }

    p {
      font-size: 26px;
    }
  }

  @include down('md') {
    text-align: center;

    h1 {
      font-size: 42px;
    }

    p {
      font-size: 24px;
    }
  }

  &.lr-hero-short {
    padding-top: 60px;
    padding-bottom: 80px;

    @include down('sm') {
      padding-top: 80px;
      padding-bottom: 60px;
    }
  }

  &.lr-hero-medium {
    padding-top: 60px;
    padding-bottom: 80px;
    min-height: 60vh;

    @include down('sm') {
      padding-top: 80px;
      padding-bottom: 60px;
    }
  }

  .lr-hero-copy-container {
    @include down('md') {
      margin-bottom: 32px;
    }
  }

  .split-hero {
    height: 100%;

    .split-hero-content {
      position: relative;

      h1,
      a {
        position: relative;
      }
    }

    .split-hero-content {
      @include down('lg') {
        display: none !important;
      }
    }

    .split-hero-container {
      grid-gap: 0;
      height: 100%;
    }
  }
}

.lr-section {
  padding-top: 100px !important;
  padding-bottom: 100px !important;

  &.lr-section-slanted {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  @include down('sm') {
    p {
      text-align: center;
    }

    ul,
    li {
      text-align: left;
    }
  }
}

.lr-section-slanted {
  -webkit-clip-path: polygon(0 3%, 100% 0, 100% 97%, 0 100%);
  clip-path: polygon(0 3%, 100% 0, 100% 97%, 0 100%);

  @include up('sm') {
    -webkit-clip-path: polygon(0 7%, 100% 0, 100% 93%, 0 100%);
    clip-path: polygon(0 7%, 100% 0, 100% 93%, 0 100%);
  }

  @include up('md') {
    -webkit-clip-path: polygon(0 12%, 100% 0, 100% 88%, 0 100%);
    clip-path: polygon(0 12%, 100% 0, 100% 88%, 0 100%);
  }
}

.lr-bg-light {
  background: $lightest-grey;
}

.lr-bg-white {
  background: #fafafa;
}

.lr-primary-bg {
  background-color: $primary;
  background-image: linear-gradient(-45deg, $primary 0%, #2e429c 100%);
  color: $white !important;
}

.light-grey-gradient-bg {
  background-image: linear-gradient(-180deg, #f5f5f5 0%, rgba(255, 255, 255, 0) 100%);
}

.clear {
  clear: both;
}

.header-icon {
  width: 100px;
}

.big-shadow {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1) !important;
}

.gutter-padding {
  @include up('xl') {
    padding-left: 0px;
    padding-right: 0px;
  }

  @include down('xl') {
    padding-left: 16px;
    padding-right: 16px;
  }

  @include down('md') {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.system-padding {
  @include up('lg') {
    padding: 32px;
  }

  @include down('lg') {
    padding: 16px;
  }

  @include down('md') {
    padding: 8px;
  }
}

.pos-relative {
  position: relative;
}

.dark-mode hr {
  border: 0.5px solid #222;
}

hr {
  border: 0.5px solid #ddd;
  margin: 0;
  padding: 0;
}

.click-mask {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
}

.lr-blur {
  filter: blur(2px);
  opacity: 0.4;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.bordered-section {
  border: 1px solid rgba(84, 105, 197, 0.25);
  padding: 48px 40px;
  background: linear-gradient(to right, $life-theme-primary-color 10px, transparent 10px) 0 0,
    linear-gradient(to left, $life-theme-primary-color 10px, transparent 10px) 100% 100%,
    linear-gradient(to bottom, $life-theme-primary-color 10px, transparent 10px) 0 0,
    linear-gradient(to top, $life-theme-primary-color 10px, transparent 10px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 108px 108px;

  p {
    margin: 0;
    padding: 0;
  }

  @include down('lg') {
    padding: 32px;
  }
}

.light-quote-bg {
  background-image: linear-gradient(90deg, #f3f3f3 6%, #e0e0e0 98%) !important;
  background-image: -webkit-linear-gradient(90deg, #f3f3f3 6%, #e0e0e0 98%) !important;
  color: $darker-grey !important;
}

.light-quote-bg-reversed {
  background-image: linear-gradient(-180deg, #f3f3f3 6%, #e0e0e0 98%) !important;
  background-image: -webkit-linear-gradient(-180deg, #f3f3f3 6%, #e0e0e0 98%) !important;
  color: $darker-grey !important;
}

.WebsitePartnersSection {
  h1 {
    font-size: 48px;
    margin-bottom: 64px;
  }

  .partner-item {
    height: 150px;
    text-align: center;

    #px img {
      max-width: 150px;
    }

    #leads-council img {
      max-width: 150px;
    }

    @include down('md') {
      height: auto;
      padding-top: 32px;
    }
  }

  img,
  svg {
    max-width: 300px;
    max-height: 200px;
  }
}

.swiper-pagination {
  position: relative !important;
  padding-top: 20px;
}
.swiper-slide {
  border-radius: var(--border-radius--m);
  border: solid 2px var(--colors-background) !important;
}
.swiper-slide-active {
  border: solid 2px var(--colors-action) !important;
}
