.lr-toggle {
  display: inline-block;
  transition: 0.1s linear;
  position: relative;
  background: $grey;
  height: 24px;
  width: 46px;
  cursor: pointer;
  border-top-left-radius: 100% 45px;
  border-bottom-left-radius: 100% 45px;
  border-top-right-radius: 100% 45px;
  border-bottom-right-radius: 100% 45px;

  &:after {
    content: '';
    transition: 0.1s;
    background: $white;
    position: absolute;
    top: 1px;
    left: 1px;
    height: 22px;
    width: 22px;
    border-radius: 50%;
  }

  &.toggled {
    background: $blue;

    &:after {
      transform: translateX(100%);
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.toggle-switch-label {
  display: inline-block;
  vertical-align: top;
  margin: 3px 0 0 16px;
  max-width: calc(100% - 62px);

  span {
    font-weight: bold;
  }
}
