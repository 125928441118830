.dark-mode .dropdown .dropdown-item:hover {
  background: $dark-mode-light;
}

.dropdown {
  position: absolute;
  min-width: 170px;
  z-index: 2;
  padding: 0;
  top: 46px;
  right: 0;
  box-shadow: $lr-box-shadow-strong;

  .dropdown-item {
    cursor: pointer;
    padding: 12px 16px;

    &:hover {
      background: $lightest-grey;
    }
  }
}
