h2 {
  margin-bottom: 0;
}

.lead-detail-map {
  height: 80px;
  width: 80px;
  z-index: 1;
  border-radius: 80px;
}

.email-link {
  font-weight: normal;
}

.timeline-container {
  max-height: 60vh;
  overflow-y: scroll;

  .status-label {
    padding: 0px 8px;
    margin: auto 4px;
  }
}

.download-pdf {
  z-index: 1;
  cursor: pointer;
}

.user-link {
  a {
    font-weight: inherit;
    color: $light-blue;
  }
}

.source-details {
  font-weight: 600;
  font-size: 13px;
  a {
    font-weight: inherit;
    font-size: inherit;
    color: $light-blue;
  }
}
